.loadingPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.page {
  min-height: 100vh;
  padding: 1rem 5rem;
}

h1 {
  color: #000000 !important;
  font-size: 32px !important;
  font-weight: 800 !important;
  margin-top: 0px !important;
  margin-bottom: 2rem !important;
}

h3 {
  color: #000000 !important;
  font-size: 32px !important;
  font-weight: 800 !important;
  margin-top: 0px !important;
  margin-bottom: 1rem !important;
}

h4 {
  color: #000000 !important;
  font-size: 24px !important;
  font-weight: 800 !important;
  margin-top: 0px !important;
  margin-bottom: 1rem !important;
}

h1 svg {
  color: #000000;
  height: 35px;
  width: 35px;
  margin-top: -3px;
  margin-left: -7px;
  cursor: pointer;
}

#loginPage {
  min-height: 100vh;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#loginPage .loginContainer{
  width: 400px;
  /* margin-top: -40px; */
}

#loginPage img{
  margin-bottom: 2rem;
}

#loginPage .primaryBtn{
  margin-bottom: 1rem;
}

input.authInput {
  border: 1px solid #d2d2d7;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  outline: none;
  height: 40px;
  /* width: 100%; */
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 16px;
}

input.authInput::placeholder {
  color: #86868B;
}

a.authLink {
  color: #CCA449
}

button.primaryBtn {
  background-color: #CCA449;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  /* font-family: Montserrat,sans-serif; */
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  width: 100%;
}

button.primaryBtn:hover {
  background-color: #CCA449;
}

button.secondaryBtn {
  background-color: white;
  color: #CCA449;
  border: 2px solid #CCA449;
  border-radius: 30px;
  cursor: pointer;
  /* font-family: Montserrat,sans-serif; */
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  width: 100%;
}

button.secondaryBtn:hover {
  background-color: white;
  color: #CCA449;
  border: 2px solid #CCA449;
}

button.secondaryBtn:active {
  background-color: white !important;
  color: #CCA449 !important;
  border: 2px solid #CCA449 !important;
}

header {
  padding: 1rem 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border-bottom: 1px solid #DDDDDD; */
}

.navbarItem {
  color: #CCA449;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  margin-right: 2rem;
  cursor: pointer;
  text-decoration: none;
}

header svg {
  width: 40px;
  height: 40px;
  color: #000000;
}

#sidebar {
  background-color: #FFFFFF;
  position: absolute;
  right: 0px;
  height: 100vh;
  width: 75%;
}

#sidebarContainer {
  padding: 1rem;
}

#sidebar svg {
  color: #000000;
  width: 35px;
  height: 35px;
  margin-bottom: 2rem;
}

#sidebar .navbarItem {
  color: #000000;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  margin-right: 0px;
  cursor: pointer;
  margin-bottom: 2rem;
  display: block;
}

.hrNavigationCardsContainer {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 1rem;
}

.navigationCardsContainer {
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  column-gap: 1rem;
}

.navigationCard {
  padding: 1rem;
  background-color: #CCA449;
  color: white;
  cursor: pointer;
  text-decoration: none;
  border-radius: 16px;
}

.navigationCard svg {
  margin-bottom: 0.25rem;
  width: 25px;
  height: 25px;
}

.navigationCard p {
  margin: 0px;
  font-weight: 700;
}

.phoneChangeRequestsListItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.phoneChangeRequestsListItem p {
  width: 33.3%;
  padding: 1rem 1.5rem;
  margin: 0px;
}

.requestsListItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  margin-bottom: 0.5rem;
  cursor: pointer;
  border-radius: 10px;
}

.requestsListItem p {
  width: 20%;
  padding: 1rem 1.5rem;
  margin: 0px;
}

.requestsListEmployeeName {
  
}

.requestsListNumberOfDays {
  
}

.requestsListType {
  
}

.requestsListDegreeOfIncapacity {
  
}

p.label {
  font-size: 14px;
  margin-bottom: 0px;
}

p.value {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

input.mainInput, select.mainInput {
  border: 1px solid #d2d2d7;
  border-radius: 10px;
  margin-bottom: 0.5rem;
  outline: none;
  height: 40px;
  width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-size: 16px;
  box-sizing: border-box;
}

input.mainInput::placeholder {
  color: #999999;
}

.modal-content {
  border-radius: 16px !important;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalHeader svg{
  color: #111111;
  height: 24px;
  width: auto;
  cursor: pointer;
}

.accordion{
  --bs-accordion-border-radius: 10px !important;
  --bs-accordion-btn-bg: transparent !important;
}

.accordion-button:focus {
  border-color: transparent !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed){
  background-color: transparent !important;
  color: #000000 !important;
}

.accordion-button::after {
  margin-bottom: -0.5rem !important;
}

.accordion-button:not(.collapsed)::after {
  margin-top: -1rem !important;
}

h2.accordion-header {
  margin-bottom: 0px !important;
}

/* .accordion-body{
  color: #868686;
} */

#desktopFooter {
  padding: 2rem 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#desktopFooter p, #desktopFooter a {
  margin-right: 2rem;
}

#mobileFooter {
  padding: 2rem 1rem;
  text-align: center;
}

#mobileFooter a {
  display: block;
}

footer p {
  color: #000000;
  margin: 0px;
}

footer a {
  color: #000000;
}

footer svg {
  width: 20px;
  height: 20px;
  color: white;
}

div:where(.swal2-icon).swal2-warning {
  color: #CCA449 !important;
  border-color: #CCA449 !important;
}

.MuiTablePagination-selectLabel.css-pdct74-MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows.css-levciy-MuiTablePagination-displayedRows {
  margin-bottom: 0px;
}

.css-5wly58-MuiDataGrid-root {
  border-radius: 16px;
}

.helpPageButtonContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}

.helpPageButtonContainer button {
  background-color: white;
  color: #CCA449;
  border: 2px solid #CCA449;
  border-radius: 30px;
  cursor: pointer;
  /* font-family: Montserrat,sans-serif; */
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  width: 100%;
}

.helpPageButtonContainer button:active {
  background-color: #CCA449;
  color: white;
  border: 2px solid #CCA449;
  border-radius: 30px;
  cursor: pointer;
  /* font-family: Montserrat,sans-serif; */
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  width: 100%;
}

.helpPageButtonContainer button:focus {
  background-color: #CCA449 !important;
  color: white !important;
  border: 2px solid #CCA449 !important;
  border-radius: 30px;
  cursor: pointer;
  /* font-family: Montserrat,sans-serif; */
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  width: 100%;
}

.helpPageButtonContainer button.active {
  background-color: #CCA449;
  color: white;
  border: 2px solid #CCA449;
}

.helpPageButtonContainer button:hover {
  background-color: white;
  color: #CCA449;
  border: 2px solid #CCA449;
  border-radius: 30px;
  cursor: pointer;
  /* font-family: Montserrat,sans-serif; */
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  width: 100%;
}

.helpPageButtonContainer button.active:hover {
  background-color: #CCA449;
  color: white;
  border: 2px solid #CCA449;
}

.helpPageButtonContainer button.left {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right-width: 1px;
}

.helpPageButtonContainer button.right {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-width: 1px;
}

.userProfilePicture {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border-radius: 100px;
}

#changeProfilePictureModalImgContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

#changeProfilePictureModalImgContainer .userProfilePicture {
  width: 200px;
  height: 200px;
}

.employeeForModalRequestContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  margin-bottom: 0.5rem;
  cursor: pointer;
  height: 40px;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.employeeForModalRequestContainer p {
  margin-bottom: 0px;
}

.adminCompaniesCompany {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 6px 20px -6px rgba(11,43,158,.15);
  margin-bottom: 0.5rem;
  border-radius: 10px;
  padding: 1rem;
}

.adminCompaniesCompany p {
  margin-bottom: 0px;
}

.adminCompaniesCompany svg {
  width: 25px;
  height: 25px;
  color: #CCA449;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .hideOnDesktop {
    display: none;
  }

  #mobileFooter {
    display: none;
  }

  header #menuToggler {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .hideOnMobile {
    display: none;
  }

  .page {
    min-height: 100vh;
    padding: 1rem 1rem;
  }

  header {
    padding: 1rem 1rem;
  }

  #desktopNavbarItems {
    display: none !important;
  }

  #desktopFooter {
    display: none;
  }

  #loginPage .loginContainer {
    width: 90%;
  }
}